import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AvailableDialog } from './available-dates.component';
import * as moment from 'moment';
import { AvailableDate } from "src/app/model/available-date.entity";

@Component({
    selector: 'available-dialog',
    templateUrl: 'available-dialog.component.html',
    styleUrls: ['available-dialog.component.css']
  })

export class AvailableDialogComponent implements OnInit {
  availableDatesFormGroup: FormGroup;
  availableDateControl: FormControl;
  availableStartTimeControl: FormControl;
  availableEndTimeControl: FormControl;

  constructor(public dialogRef: MatDialogRef<AvailableDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:AvailableDialog, fb: FormBuilder) {
    dialogRef.disableClose = true;
    this.availableDateControl = new FormControl(new Date(data.availableDate.date), [Validators.required]);
    this.availableStartTimeControl = new FormControl(moment(data.availableDate.startTime).format('HH:mm'), [Validators.required]);
    this.availableEndTimeControl = new FormControl(moment(data.availableDate.endTime).format('HH:mm'), [Validators.required]);

    this.availableDatesFormGroup = fb.group({
      date: this.availableDateControl,
      startTime: this.availableStartTimeControl,
      endTime: this.availableEndTimeControl
    });
  }

  ngOnInit() {

  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    const availableDate: AvailableDate = this.getFormatedForm();
    this.dialogRef.close(availableDate);
  }

  getFormatedForm() {
    return new AvailableDate(
      this.data.availableDate.id,
      moment(this.availableDateControl.value).toDate(),
      moment(this.availableStartTimeControl.value, 'HH:mm').toDate(),
      moment(this.availableEndTimeControl.value, 'HH:mm').toDate()
    );
  }
}
