import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AvailableDate } from '../model/available-date.entity';
import { Constants } from '../util/constants';
import * as moment from 'moment';

@Injectable()
export class AvailabiltyService extends Constants {
    apiBaseUrl:string = `${this.API_BASE_URL}/availability`

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': this.AUTHORIZATION,
          'Accept': 'application/json',
        })
      };

      constructor( private http: HttpClient){
          super();
      }

      public getAllAvailabileDates() {
          return this.http.get<AvailableDate[]>(this.apiBaseUrl, this.httpOptions);
      }

      // "yyyy-MM-dd"
      public getAvailabilityByDate(date: string) {
          return this.http.get<AvailableDate[]>(this.apiBaseUrl + '/' + date, this.httpOptions);
      }

      public saveAvailavilityDate(availableDate: AvailableDate) {
          return this.http.put<AvailableDate>(this.apiBaseUrl, AvailableDate.transformForSave(availableDate), this.httpOptions);
      }

      public deleteAvailabilityDate(availabilityDate: AvailableDate) {
          return this.http.delete<AvailableDate>(this.apiBaseUrl + '/' + availabilityDate.id, this.httpOptions)
      }
}
