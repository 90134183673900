import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Table } from 'src/app/model/table.entity';
import { TableService } from 'src/app/service/table.service';
import { TableDialogComponent } from './table-dialog.component';

export interface TableDialog {
  header:string; 
 table:Table;
}

@Component({
  selector: 'app-table-management',
  templateUrl: 'table-management.component.html',
  styleUrls: ['table-management.component.css']
})

export class TableManagementComponent implements OnInit {
  tables:Table[];
  dataSource: MatTableDataSource<Table>;
  displayedColumns:Array<string> = ["tableName", "tabelMaxSize", "turnAroundTime", "options"];
  tableDialogRef:MatDialogRef<TableDialogComponent>;

  constructor(public dialog:MatDialog, public tableService:TableService) { }

  ngOnInit() {
    this.tableService.getTables().subscribe(tables => {
      this.tables = tables;
      this.updateDataTable();
    })
  }

  updateDataTable() {
    this.dataSource = new MatTableDataSource<Table>(this.tables);
  }

  onEditTableClick(table:Table) {
    this.tableDialogRef = this.dialog.open(TableDialogComponent, {
      width: '350x',
      data: {
          header: 'Edit',
          table: table
    }})
    this.tableDialogRef.afterClosed().subscribe(result => {
      this.tableService.saveTable(result).subscribe(table => {
        let index = this.tables.findIndex(element => element.id === table.id);
        this.tables[index] = table;
        this.updateDataTable();
      })
    })
  }

  onAddTableClick() {
    this.tableDialogRef = this.dialog.open(TableDialogComponent, {
      width: '350x',
      data: {
          header: 'Add',
          table: new Table()
    }});
    this.tableDialogRef.afterClosed().subscribe(result => {
      this.tableService.saveTable(result).subscribe(table => {
        this.tables.push(table);
        this.updateDataTable();
      });
    });
  }

  onDeleteTableClick() {
    // TODO: Add Confirmation about delete
  }
}
