import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Reservation } from '../../model/reservation.entity';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog.component';
import { ReservationDialogComponent } from '../reservation-dialog/reservation-dialog.component';
import { ReservationService } from 'src/app/service/reservation.service';
import { Constants } from 'src/app/util/constants';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
    selector: 'dashboard',
    styleUrls: ['dashboard.component.css'],
    templateUrl: 'dashboard.component.html'
})

export class DashboardComponent extends Constants {
    selectedDate:FormControl;
    reservations:Reservation[]
    todaysDate:Date = new Date();
    displayedColumns:Array<string> = ["customerName", "reservationTime", "reservationSize", "tableNumber", "options"];
    dataSource:MatTableDataSource<Reservation>;
    actualPaginator:MatPaginator;
    waitDialogRef:MatDialogRef<ConfirmDialogComponent>;
    reservationDialogRef:MatDialogRef<ReservationDialogComponent>;
    everyFiveSeconds:Observable<number> = timer(0, 5000);
    subscription:Subscription;

    @ViewChild(MatPaginator, { static: false })
    set paginator(value:MatPaginator) {
        this.actualPaginator = value;
    }

    constructor( public waitDialog: MatDialog, public reservationService:ReservationService ) {
        super();
    }

    ngOnInit() {
        this.selectedDate = new FormControl(new Date());
        this.showWaitDialog("Loading Reservation Dashboard", "", "350px");
        this.getReservations(true);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getReservations(isNgOnInit:boolean) {
        this.subscription = this.everyFiveSeconds.subscribe(() => {
            this.reservationService.getReservationsByDate(moment(this.selectedDate.value).format(this.RESERVATION_DATE_FORMAT_MOMENT)).subscribe(reservations => {
                this.reservations = reservations;
                this.updateDataTable(isNgOnInit);
                this.waitDialogRef.close();
            }, err => {
                this.waitDialogRef.close();
                console.error("[ERROR] getReservations()", err)
            })
        });
    }

    updateDataTable(isNgOnInit:boolean) {
        this.dataSource = new MatTableDataSource<Reservation>(this.reservations);
        this.dataSource.paginator = this.actualPaginator;
        if (isNgOnInit) {
            this.dataSource.paginator.firstPage();
        }
    }

    showWaitDialog(body:string, cancel:string, dialogWidth:string) {
        this.waitDialogRef = this.waitDialog.open(ConfirmDialogComponent, {
            width: dialogWidth,
            data: {
                header: '',
                body: body,
                cancel: cancel,
                confirm: '',
                spinner: true
            }

        });
    }

    onDatePickerChange() {
        this.subscription.unsubscribe();
        this.getReservations(false);
    }

    onReservationEditClick(element) {
      this.reservationDialogRef = this.waitDialog.open(ReservationDialogComponent, {
          width: '350x',
          data: {
            header: 'Edit Reservation',
            reservation: element
          }
      })
    }

    onReservationDeleteClick(reservation:Reservation) {
      this.reservationService.deleteReservation(reservation).subscribe(result => {
        let index = this.reservations.findIndex(element => element.id === reservation.id);
        this.reservations.splice(index, 1);
        this.updateDataTable(false);
      })
    }
}
