<h1 class="mat-display-1" style="margin: 15px;">Table Management</h1>

<button mat-flat-button style="margin: 15px;" (click)="onAddTableClick()"
    color="primary"><mat-icon style="margin-right: 10px; margin-bottom: 4px;">add_circle</mat-icon>Add Table</button>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="tableName">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tabelMaxSize">
        <mat-header-cell *matHeaderCellDef>Max Size</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.maxSize }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="turnAroundTime">
        <mat-header-cell *matHeaderCellDef>Turn Around Time</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.turnAroundTime }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-column-right-align">
            <div class="edit-button">
                <button mat-button color="primary" (click)="onEditTableClick(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <button mat-button color="warn">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
