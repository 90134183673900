<h2>{{data.header}} Table</h2>

<form [formGroup]="tableFormGroup" (ngSubmit)="onSubmit()">
  <div>
  <mat-form-field appearance="fill">
      <mat-label>Table Name</mat-label>
      <input matInput name="tableName" [formControl]="tableNameControl">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Table Max Size</mat-label>
        <mat-select name="tableMaxSize" [formControl]="tableMaxSizeControl">
          <mat-option *ngFor="let size of maxSizes" [value]="size">
            {{size}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Turn Around Time</mat-label>
      <mat-select name="turnAroundTime" [formControl]="turnAroundTimeControl">
        <mat-option *ngFor="let turnAroundTime of turnAroundTimes" [value]="turnAroundTime">
          {{turnAroundTime}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
    <button mat-raised-button class="button-delete"  color="warn" (click)="onCancelClick()"><mat-icon>cancel</mat-icon> Cancel</button>
    <button mat-raised-button type="submit" class="button-save" [disabled]="tableFormGroup.invalid"  color="primary"><mat-icon>save</mat-icon> Save</button>
</form>
