import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Reservation} from '../../model/reservation.entity';
import {AvailabiltyService} from '../../service/available-date.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {Constants} from '../../util/constants';
import {TableService} from '../../service/table.service';
import {ReservationService} from '../../service/reservation.service';

export interface DialogData {
  reservation: Reservation,
  header: string,
  cancel: string,
  confirm: string,
}

@Component({
  selector: 'reservation-dialog',
  styleUrls: ['reservation-dialog.component.css'],
  templateUrl: 'reservation-dialog.component.html'
})
export class ReservationDialogComponent extends Constants{
  header: string;
  reservation: Reservation;
  minDate = moment()
  timeOptions: string[] = this.RESERVATION_TIME_OPTIONS;
  sectionOptions: string[] = this.RESERVATION_SECTION_OPTIONS;
  partySizeOptions: number[] = this.RESERVATION_SIZE_OPTIONS;
  tableOptions: string[];

  reservationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ReservationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private availabilityService: AvailabiltyService,
    private tableService: TableService,
    private reservationService: ReservationService) {
    super();
    dialogRef.disableClose = true;
    this.reservation = data.reservation;
    this.header = data.header;
  }

  ngOnInit():void {
    this.populateTables();
    this.buildForm();
  }

  buildForm():void {
    this.reservationForm = this.fb.group({
      firstName: new FormControl(this.reservation.firstName),
      lastName: new FormControl(this.reservation.lastName),
      date: new FormControl(moment(this.reservation.reservationTime)),
      time: new FormControl(moment(this.reservation.reservationTime).format(this.RESERVATION_TIME_MOMENT)),
      size: new FormControl(this.reservation.reservationSize),
      section: new FormControl(this.reservation.reservationArea),
      table: new FormControl(this.reservation.reservationTable)
    })
  }

  populateTables():void {
    this.tableService.getTables().subscribe(response => {
      this.tableOptions = [];
      response.forEach(table => {
        this.tableOptions.push(table.name);
      })
    })
  }

  onSubmitClick() {
    // TODO: Validate
    this.reservation.firstName = this.reservationForm.controls.firstName.value;
    this.reservation.lastName = this.reservationForm.controls.lastName.value;
    const momentTime = moment(this.reservationForm.controls.time.value + " pm", this.RESERVATION_TIME_MOMENT + " A").format("HH:mm");
    this.reservation.reservationTime = this.reservationForm.controls.date.value.format(this.RESERVATION_DATE_FORMAT_MOMENT) + " " + momentTime;
    this.reservation.reservationSize = this.reservationForm.controls.size.value;
    this.reservation.reservationArea = this.reservationForm.controls.section.value;
    this.reservation.reservationTable = this.reservationForm.controls.table.value;

    this.reservationService.saveReservation(this.reservation).subscribe(console.log);

    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
