<button mat-flat-button style="margin: 15px;" (click)="onAddClick()"
    color="primary"><mat-icon style="margin-right: 10px; margin-bottom: 4px;">add_circle</mat-icon>Add Date</button>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.date | date:'mediumDate' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef>Start Time</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.startTime | date:'shortTime' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef>End Time</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.endTime | date:'shortTime' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-column-right-align">
            <div class="edit-button">
                <button mat-button color="primary" (click)="onEditClick(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <button mat-button color="warn" (click)="onDeleteClick(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
