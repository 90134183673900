import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableDialog } from './table-management.component';

@Component({
    selector: 'add-table-dialog',
    templateUrl: './table-dialog.component.html',
    styleUrls: ['./table-dialog.component.css']
})

export class TableDialogComponent implements OnInit {
    tableFormGroup:FormGroup;
    tableNameControl:FormControl;
    tableMaxSizeControl:FormControl;
    turnAroundTimeControl:FormControl;
    maxSizes:number[] = [1, 2, 3, 4, 5, 6];
    turnAroundTimes:number[] = [30, 60, 90, 120];

    constructor(public dialogRef:MatDialogRef<TableDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:TableDialog, fb: FormBuilder) {
        dialogRef.disableClose = true;

        this.tableNameControl = new FormControl(data.table.name, [Validators.required]);
        this.tableMaxSizeControl = new FormControl(data.table.maxSize, [Validators.required]);
        this.turnAroundTimeControl = new FormControl(data.table.turnAroundTime, [Validators.required]);

        this.tableFormGroup = fb.group({
            id: new FormControl(data.table.id),
            name: this.tableNameControl,
            maxSize: this.tableMaxSizeControl,
            turnAroundTime: this.turnAroundTimeControl
        })
    }

    ngOnInit() {
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.dialogRef.close(this.tableFormGroup.value);
    }
}