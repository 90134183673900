import { Component, ViewChild } from "@angular/core";
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/util/constants';
import { AvailableDate } from 'src/app/model/available-date.entity';
import { AvailabiltyService } from 'src/app/service/available-date.service';

@Component({
    selector: 'availability',
    styleUrls: ['availability.component.css'],
    templateUrl: 'availability.component.html'
})

export class AvailabilityComponent extends Constants {

    availableDate: AvailableDate
    selectedDate:Date
    date:FormControl = new FormControl(new Date());

    constructor(public availabilityService: AvailabiltyService) {
        super();
    }
    
    ngOnInit() {
        let todaysDate = new Date();
        // this.availableDate = this.AVAILABILITY_DATES_STANDARD;
        // this.availableDate.date = new Date(todaysDate.toLocaleDateString());
        // TODO: Call service to load availability for today.
        this.availabilityService.getAvailabilityByDate(todaysDate.toJSON().slice(0,10)).subscribe(availableDate => {
            if (availableDate.length > 0) {
                console.log("[INFO] ngOnInit()", availableDate);
                this.availableDate = availableDate[0];
                return;
            }
            // this.availableDate.availableTableSizesList = [];
            // this.availableDate.availableTimeList = [];
        }, err => {
            console.error("[ERROR] Error saving ngOnInit()", err)
        })

    }

    saveAvailabileDates() {
        console.log(this.availableDate)
        this.availabilityService.saveAvailavilityDate(this.availableDate).subscribe( availableDate => {
            if (availableDate) {
                console.log("[INFO] saveAvailableDates()", availableDate)
            } else {
                console.log("[INFO]", availableDate)
            }
        }, err => {
            console.error("[ERROR] Error saving availability", err.message)
        })
        // TODO: Pop-Up Dialog for successful saving
    }

    loadStandardTimesAndSizes() {
        // this.availableDate.availableTableSizesList = this.AVAILABLE_TABLE_SIZES_STANDARD;
        // this.availableDate.availableTimeList = this.AVAILABILE_TIMES_STANDARD;
        this.saveAvailabileDates();
    }

    handleOnCalendarChange(event) {
        // TODO: Change date and load values based on date seleceted
        this.availabilityService.getAvailabilityByDate(event.value.toJSON().slice(0, 10)).subscribe( availableDate => {
            if (availableDate.length > 0) {
                console.log("[INFO] handleOnCalendarChange(event) response > 0", availableDate);
                this.availableDate = availableDate[0];
            } else {
                console.log("INFO] handleOnCalendarChange(event) response === 0", availableDate);
                this.availableDate = {
                    ...this.availableDate,
                    date: event.value,
                    // availableTableSizesList: [],
                    // availableTimeList: []
                }
            }
            
        }, error => {
            console.error("[ERROR] Error retrieving Availability Date", error.message)
        })
    }

    handleOnAddAvailabilityTime() {
        // TODO: Later implementation
    }

    handleOnAddAvailabilitySize() {
        // TODO: Later implementation
    }

    handleOnDeleteAvailabilityTime() {
        // TODO: Later implementation
    }

    handleOnDeleteAvailabilitySize() {
        // TODO: Later implementation
    }
}