import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    header: string,
    body: string,
    cancel: string,
    confirm: string,
    spinner: boolean,
    result: any
}

@Component({
    selector: 'confirm-dialog',
    styleUrls: ['confirm-dialog.component.css'],
    templateUrl: 'confirm-dialog.component.html'
})

export class ConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:DialogData) {
            dialogRef.disableClose = true;
        }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}