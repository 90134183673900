interface ReservationInterface {
    id?: number,
    customerId?: number,
    firstName?: string,
    lastName?: string,
    phoneNumber?: number,
    reservationTime?: string,
    reservationArea?: string,
    reservationSize?: number,
    reservationTable?: string,
    createDate?: Date,
    updateDate?: Date
}

export class Reservation implements ReservationInterface {
  constructor(
    public id?: number,
    public customerId?: number,
    public firstName?: string,
    public lastName?: string,
    public phoneNumber?: number,
    public reservationTime?: string,
    public reservationArea?: string,
    public reservationSize?: number,
    public reservationTable?: string,
    public createDate?: Date,
    public updateDate?: Date
  ) {}

  getReservationDate() {

  }
}
