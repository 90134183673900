<div style=''>
<mat-toolbar color='primary'>
  <span>Capozio's Reservations</span>
  <span class="example-spacer"></span>
  <button mat-button color='white' (click)="showAddReservation()"><mat-icon>add_circle</mat-icon> Reservation</button>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/availability">
      <mat-icon>event_available</mat-icon>
      <span>Availability</span>
    </button>
    <button mat-menu-item routerLink="/reservations">
      <mat-icon>dashboard</mat-icon>
      <span>Reservations</span>
    </button>
    <button mat-menu-item routerLink="/tables">
      <mat-icon>tablet</mat-icon>
      <span>Table Management</span>
    </button>
  </mat-menu>
</mat-toolbar>

<router-outlet></router-outlet>
</div>

<mat-toolbar class="footer" color='primary'>
  <div class="footer-copyright">
    <span class="mat-body" style="margin-left: 10px;">
      &copy; Copyright Capozio's 2022 v3.0.0
      </span>
  </div>
</mat-toolbar>
