export class Constants {
  AUTHORIZATION = 'Basic cmVzZXJ2YXRpb25zRGFzaGJvYXJkOkVKWDJkZXQ5dm55M3BycHlhaw==';
  TOKEN = 'jwtToken';
  IS_USER_LOGGED_IN = 'isUserLoggedIn';
  // API_BASE_URL:string = "http://localhost:8080";
  API_BASE_URL = 'https://api.capozios.com';

  RESERVATION_DATE_FORMAT_MOMENT = 'YYYY-MM-DD';
  RESERVATION_TIME_MOMENT = 'h:mm';
  RESERVATION_BACKEND_DATE_MOMENT = 'YYYY-MM-DD hh:mm';
  RESERVATION_TIME_OPTIONS: string[] = [
    "4:00", "4:15", "4:30", "4:45",
    "5:00", "5:15", "5:30", "5:45",
    "6:00", "6:15", "6:30", "6:45",
    "7:00", "7:15", "7:30", "7:45",
    "8:00", "8:15", "8:30", "8:45",
    "9:00", "9:15", "9:30", "9:45",
  ];
  RESERVATION_SECTION_OPTIONS: string[] = ['Sports', 'Dinning'];
  RESERVATION_SIZE_OPTIONS: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
}
