<h2>{{data.header}} Date</h2>

<form [formGroup]="availableDatesFormGroup" (ngSubmit)="onSubmit()">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" name="date" [formControl]="availableDateControl">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Start Time</mat-label>
        <input matInput type="time" name="startTime" [formControl]="availableStartTimeControl">
      </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>End Time</mat-label>
      <input matInput type="time" name="endTime" [formControl]="availableEndTimeControl">
    </mat-form-field>
  </div>
    <button mat-raised-button class="button-delete" color="warn" (click)="onCancelClick()"><mat-icon>cancel</mat-icon> Cancel</button>
    <button mat-raised-button type="submit" class="button-save" [disabled]="availableDatesFormGroup.invalid"  color="primary"><mat-icon>save</mat-icon> Save</button>
</form>
