<h1 class="mat-display-1" style="margin: 15px;">Reservations</h1>

<mat-form-field style="margin: 15px;" appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="selectedDate" (dateChange)="onDatePickerChange()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #picker></mat-datepicker>
</mat-form-field>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="reservationId">
      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.lastName }}, {{ element.firstName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="reservationTime">
      <mat-header-cell *matHeaderCellDef> Reservation Time </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.reservationTime | date: 'MMM dd EEEE h:mm a' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="reservationSize">
      <mat-header-cell *matHeaderCellDef> Reservation Size </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.reservationSize }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="area">
      <mat-header-cell *matHeaderCellDef> Area </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.area }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tableNumber">
      <mat-header-cell *matHeaderCellDef> Table # </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.reservationTable }}</mat-cell>
    </ng-container>

  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class="right-column-right-align">
      <div class="edit-button">
        <button mat-button color="primary" (click)="onReservationEditClick(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <button mat-button color="warn" (click)="onReservationDeleteClick(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<span>
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="'10'" showFirstLastButtons></mat-paginator>
</span>
