import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Reservation } from '../model/reservation.entity';
import { Constants } from '../util/constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ReservationService extends Constants {
    apiBaseUrl = `${this.API_BASE_URL}/reservations`;

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: this.AUTHORIZATION,
          Accept: 'application/json'
        })
      };

    constructor(private http: HttpClient, private cookieService: CookieService){
        super();
    }

    // "yyyy-MM-dd"
    public getReservationsByDate(date: string) {
        return this.http.get<Reservation[]>(this.apiBaseUrl + '/' + date, this.httpOptions );
    }

    public saveReservation(reservation: Reservation) {
        return this.http.post<Reservation>(this.apiBaseUrl, reservation, this.httpOptions);
    }

    public deleteReservation(reservation: Reservation) {
        return this.http.delete<Reservation>(this.apiBaseUrl + '/' + reservation.id, this.httpOptions);
    }
}
