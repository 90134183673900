import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from  '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ConfirmDialogComponent } from './component/dialog/confirm-dialog.component';
import { ReservationDialogComponent } from './component/reservation-dialog/reservation-dialog.component';
import { AvailabilityComponent } from './component/availability/availability.component';
import { AvailableDatesComponent } from './component/availability/available-dates.component';
import { AvailableDialogComponent } from './component/availability/available-dialog.component';
import { TableManagementComponent } from './component/table-management/table-management.component';
import { EditTableComponent } from './component/edit-table/edit-table.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { AvailabiltyService } from 'src/app/service/available-date.service';
import { ReservationService } from 'src/app/service/reservation.service';
import { TableService } from 'src/app/service/table.service';
import { TableDialogComponent } from './component/table-management/table-dialog.component';
import {AuthService} from './service/auth.service';
import {LoginComponent} from './component/login/login.component';
import {MatCardModule} from '@angular/material/card';
import {AuthGuard} from './auth.guard';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent, DashboardComponent, ConfirmDialogComponent, ReservationDialogComponent, AvailabilityComponent, TableManagementComponent, EditTableComponent,
    TableDialogComponent, AvailableDatesComponent, AvailableDialogComponent, LoginComponent
  ],
  imports: [
    HttpClientModule, BrowserModule, AppRoutingModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, MatToolbarModule, MatDatepickerModule,
    MatFormFieldModule, MatNativeDateModule, MatInputModule, MatButtonModule, MatIconModule, MatTableModule,
    MatPaginatorModule, MatProgressSpinnerModule, MatDialogModule, MatSelectModule, MatMenuModule, MatListModule,
    MatGridListModule, MatTabsModule, MatMomentDateModule, MatCardModule
  ],
  providers: [
    ReservationService, AvailabiltyService, TableService, AuthService, AuthGuard, CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
