import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Table } from "../model/table.entity";
import { Constants } from "../util/constants";

@Injectable()
export class TableService extends Constants {
    apiBaseUrl:string = `${this.API_BASE_URL}/tables`;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.AUTHORIZATION,
            'Accept': 'application/json'
        })
    };

    constructor(private http:HttpClient) {
        super();
    }

    public getTables() {
        return this.http.get<Table[]>(this.apiBaseUrl, this.httpOptions);
    }

    public saveTable(table:Table) {
        return this.http.post<Table>(this.apiBaseUrl, table, this.httpOptions)
    }

    public deleteTable(table:Table) {
        return this.http.delete<Table>(this.apiBaseUrl + `/${table.id}`, this.httpOptions)
    }
}