import * as moment from 'moment/moment';

export class AvailableDate {
    constructor(
        public id?: number,
        public date: Date = new Date(),
        public startTime: Date = null,
        public endTime: Date = null
    ){}

    public static transformForSave(availableDate: AvailableDate){
      console.log(availableDate);
      return {
        date: moment(availableDate.date).format('YYYY-MM-DD'),
        startTime: availableDate.startTime,
        endTime: availableDate.endTime,
      };
    }
}
