<h2 *ngIf="data.header" mat-dialog-title>{{ data.header }}</h2>
<div mat-dialog-content>
    <div class="spinner-wrapper">
        <mat-progress-spinner *ngIf="data.spinner == true" class="spinner-margin" [color]="'primary'" [mode]="'indeterminate'" [value]="'10'" [diameter]="'20'"></mat-progress-spinner>
        <p id="message">{{ data.body }}</p>
    </div>
</div>
<div *ngIf="data.confirm || data.cancel" mat-dialog-actions class="float-right">
    <button *ngIf="data.cancel" mat-stroked-button (click)="onCancelClick()">{{ data.cancel }}</button>
    <button *ngIf="data.confirm" mat-stroked-button cdkFocusInitial [mat-dialog-close]="data.result">{{ data.confirm }}</button>
</div>