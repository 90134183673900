import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './component/dialog/confirm-dialog.component';
import { ReservationDialogComponent } from './component/reservation-dialog/reservation-dialog.component';
import { Reservation } from './model/reservation.entity';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title:string = 'reservations-frontend';
  waitDialogRef: MatDialogRef<ConfirmDialogComponent>;
  reservationDialogRef: MatDialogRef<ReservationDialogComponent>;

  constructor( public waitDialog: MatDialog ) {
  }

  ngOnInit() {
  }

  showAddReservation() {
    this.reservationDialogRef = this.waitDialog.open(ReservationDialogComponent, {
        width: '350x',
        data: {
            header: 'Add Reservation',
            reservation: new Reservation(),
            cancel: '',
            confirm: '',
        }
    })
  }
}
