import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { element } from 'protractor';
import { AvailableDate } from 'src/app/model/available-date.entity';
import { AvailabiltyService } from 'src/app/service/available-date.service';
import { AvailableDialogComponent } from './available-dialog.component';

export interface AvailableDialog {
  header:string;
  availableDate:AvailableDate;
 }

@Component({
    selector: 'available-dates',
    templateUrl: 'available-dates.component.html',
    styleUrls: ['available-dates.component.css']
  })

  export class AvailableDatesComponent implements OnInit {
    availableDates:AvailableDate[];
    dataSource: MatTableDataSource<AvailableDate>;
    displayedColumns:Array<string> = ["date", "startTime", "endTime", "options"]
    availableDateDialogRef:MatDialogRef<AvailableDialogComponent>;

    constructor(public dialog:MatDialog, public availabilityService:AvailabiltyService) { }

    ngOnInit() {
      this.availabilityService.getAllAvailabileDates().subscribe(dates => {
        this.availableDates = dates;
        this.updateDataTable();
      })
    }

    onAddClick() {
      this.availableDateDialogRef = this.dialog.open(AvailableDialogComponent, {
        width: '350x',
        data: {
            header: 'Add',
            availableDate: new AvailableDate()
      }});
      this.availableDateDialogRef.afterClosed().subscribe(result => {
        this.availabilityService.saveAvailavilityDate(result).subscribe(availableDate => {
          this.availableDates.push(availableDate);
          this.updateDataTable();
        })
      })
    }

    onEditClick(availableDate:AvailableDate) {
      this.availableDateDialogRef = this.dialog.open(AvailableDialogComponent, {
        width: '350px',
        data: {
          header: 'Edit',
          availableDate: availableDate
        }});
      this.availableDateDialogRef.afterClosed().subscribe(result => {
        this.availabilityService.saveAvailavilityDate(result).subscribe(availableDate => {
          let index = this.availableDates.findIndex(element => element.id === availableDate.id);
          this.availableDates[index] = availableDate;
          this.updateDataTable();
        })
      })
    }

    onDeleteClick(availableDate:AvailableDate) {
      this.availabilityService.deleteAvailabilityDate(availableDate).subscribe(result => {
        let index = this.availableDates.findIndex(element => element.id === availableDate.id);
        this.availableDates.splice(index, 1);
        this.updateDataTable();
      })
    }

    updateDataTable() {
      this.dataSource = new MatTableDataSource<AvailableDate>(this.availableDates);
    }
  }
