<h2>{{header}}</h2>
<div>
  <form [formGroup]="reservationForm">
    <mat-form-field appearance="fill" class="input-width-236">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="input-width-236">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
      </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput formControlName="date"
             [matDatepicker]="datePicker"
             [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="input-width-236">
      <mat-label>Time</mat-label>
      <mat-select formControlName="time">
        <mat-option *ngFor="let time of timeOptions" [value]="time">{{time}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="input-width-236">
      <mat-label>Party Size</mat-label>
      <mat-select formControlName="size">
        <mat-option *ngFor="let size of partySizeOptions" [value]="size">{{size}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="input-width-236">
      <mat-label>Section</mat-label>
      <mat-select formControlName="section">
        <mat-option *ngFor="let section of sectionOptions" [value]="section">{{section}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="input-width-236">
      <mat-label>Table #</mat-label>
      <mat-select formControlName="table">
        <mat-option *ngFor="let table of tableOptions" [value]="table">{{table}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<button class="button-delete" mat-raised-button color="warn" (click)="onCancelClick()"><mat-icon>cancel</mat-icon> Cancel</button>
<button class="button-save" mat-raised-button color="primary" (click)="onSubmitClick()"><mat-icon>save</mat-icon> Save</button>

