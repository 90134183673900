import { Injectable, OnDestroy } from '@angular/core';
import {Observable, of} from 'rxjs';
import {CanActivate, Router} from '@angular/router';
import {delay, switchMap, tap} from 'rxjs/operators';
import { Constants } from '../util/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from "../model/token.entity";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Constants  {
  constructor(private router: Router, private http: HttpClient, private cookieService: CookieService) {
    super();
  }
  apiBaseUrl = `${this.API_BASE_URL}/auth/token`;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      Accept: 'application/json'
    })
  };

  isLoggedIn() {
    return this.cookieService.get('token') !== "";
  }

  login(userName: string, password: string): Observable<any> {
    return this.getToken(userName, password).pipe(
      tap(token => {
        if (token.accessToken && token.tokenType) {
          console.log(token.accessToken);
          this.cookieService.set('token', token.accessToken, null, '/', null, true, 'Strict');
          localStorage.setItem('isUserLoggedIn', 'true');
        }
      }),
      switchMap(() => {
        return of(true); // Return a new observable that resolves to true
      })
    );
  }

  logout(): void {
    this.cookieService.delete('token', '/', null, true, 'Strict');
    localStorage.setItem('isUserLoggedIn', 'false');
    this.router.navigateByUrl('/login');
  }

  public getToken(username: string, password: string) {
    const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    return this.http.post<Token>(this.apiBaseUrl, body, this.httpOptions);
  }
}
