<h1 class="mat-display-1" style="margin: 15px;">Availability</h1>

<!-- <mat-form-field style="margin-left: 15px;" appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="handleOnCalendarChange($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field> -->

<!-- <div>
    <button mat-button color="primary" (click)="loadStandardTimesAndSizes()">Load Standard Times and Table Sizes</button>
</div> -->
<mat-tab-group>

    <mat-tab label="Available Days">
        <available-dates></available-dates>
    </mat-tab>

    <mat-tab label="Available Times">
        <!-- <h2 class="mat-h2" style="margin-left: 15px; margin-top: 5px;">Available Times <button mat-icon-button><mat-icon style="color: green;">add_circle</mat-icon></button></h2>
        <mat-grid-list cols="3" rowHeight="30px" style="margin-left: 15px;" >
            <mat-grid-tile *ngFor="let availableTime of availableDate.availableTimeList">
                <button mat-icon-button><mat-icon color="warn">cancel</mat-icon></button> {{availableTime.time}}
            </mat-grid-tile>
        </mat-grid-list> -->
    </mat-tab>

    <mat-tab label="Available Table Sizes">
        <!-- <h2 class="mat-h2" style="margin-left: 15px; margin-top: 5px;">Available Table Sizes <button mat-icon-button><mat-icon style="color: green;">add_circle</mat-icon></button></h2>
        <mat-list style="margin-left: 15px;">
            <mat-list-item *ngFor="let availableSize of availableDate.availableTableSizesList">
                <button mat-icon-button><mat-icon color="warn">cancel</mat-icon>{{availableSize.size}}</button>
            </mat-list-item>
        </mat-list> -->
    </mat-tab>
</mat-tab-group>